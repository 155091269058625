const chartColors = {
  D0: { bgColor: "rgba(255,177,193,255)", borderColor: "rgba(255,92,126,255)" },
  D1: { bgColor: "rgba(255,207,159,255)", borderColor: "rgba(255,154,55,255)" },
  D2: { bgColor: "rgba(255,230,170,255)", borderColor: "rgba(255,203,78,255)" },
  D3: { bgColor: "rgba(165,223,223,255)", borderColor: "rgba(65,188,188,255)" },
  D4: { bgColor: "rgba(154,208,245,255)", borderColor: "rgba(45,157,234,255)" },
  D5: {
    bgColor: "rgba(228,229,231,255)",
    borderColor: "rgba(204,206,209,255)",
  },
  D6: {
    bgColor: "rgba(204,178,255,255)",
    borderColor: "rgba(153,102,255,255)",
  },
};

export const defaultData = {
  labels: ["jul", "aug", "sep", "oct", "nov", "dec", "jan"],
  datasets: [
    {
      label: "D0",
      data: [66, 75, 50, 51, 56, 60, 61],
      fill: true,
      backgroundColor: chartColors.D0.bgColor,
      borderColor: chartColors.D0.borderColor,
    },
    {
      label: "D1",
      data: [103, 133, 79, 90, 119, 111, 106],
      fill: true,
      backgroundColor: chartColors.D1.bgColor,
      borderColor: chartColors.D1.borderColor,
    },
    {
      label: "D2",
      data: [194, 279, 170, 205, 239, 224, 198],
      fill: true,
      backgroundColor: chartColors.D2.bgColor,
      borderColor: chartColors.D2.borderColor,
    },
    {
      label: "D3",
      data: [251, 338, 200, 227, 285, 263, 225],
      fill: true,
      backgroundColor: chartColors.D3.bgColor,
      borderColor: chartColors.D3.borderColor,
    },
    {
      label: "D4",
      data: [307, 365, 265, 273, 334, 335, 252],
      fill: true,
      backgroundColor: chartColors.D4.bgColor,
      borderColor: chartColors.D4.borderColor,
    },
    {
      label: "D5",
      data: [335, 387, 316, 341, 388, 406, 286],
      fill: true,
      backgroundColor: chartColors.D5.bgColor,
      borderColor: chartColors.D5.borderColor,
    },
    {
      label: "D6",
      data: [406, 460, 367, 411, 453, 466, 312],
      fill: true,
      backgroundColor: chartColors.D6.bgColor,
      borderColor: chartColors.D6.borderColor,
    },
  ],
};
