import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import forest from "../components/map/icons/baseline_forest_black_24dp.png";
import yard from "../components/map/icons/baseline_yard_black_24dp.png";
import {
  fetchOveviewDataRequest,
  fetchOveviewDataSuccess,
} from "../store/actions/oveview.actions";
import {
  fetchPlanningDataRequest,
  fetchPlanningDataSuccess,
} from "../store/actions/planning.actions";
import { setWaterSystem } from "../store/actions/waterSystem.actions";
// import { fetchWaterSystemsDataRequest, fetchWaterSystemsDataSuccess } from "../store/actions/waterSystems.actions";
// import { fetchWeatherForecastDataRequest, fetchWeatherForecastDataSuccess } from "../store/actions/weatherForecast.actions";

const SetDummyData = (props) => {
  const dispatch = useDispatch();

  // const fields = [
  //     {
  //         id: '550e8400-e29b-41d4-a716-446655440001',
  //         name: 'Field 1',
  //         polygons: [{ id: '550e8400-e29b-41d4-a716-446655440002', position: [[40.1631329, 8.8642502], [40.166806, 8.9641571], [40.0849013, 8.9590073], [40.077546, 8.8618469], [40.1631329, 8.8642502]], color: 'blue', popup: null, tooltip: null }],
  //         irrigations_system: {
  //             system: 'sprinkler',
  //             number: '2',
  //             amount: '222'
  //         },
  //         soil_type: {
  //             type: 'texture',
  //             clay: '34',
  //             silt: '35',
  //             sand: '36'
  //         },
  //         crop: {
  //             type: 'annual',
  //             species: 'tomato',
  //             variety_name: 'tomato name',
  //             variety_code: 'code',
  //             distance_line: '40',
  //             distance_individuals: '20',
  //             planting_date: '28/08/2023',
  //             harvest_date: '28/08/2023',
  //             crop_yields: '1200',
  //             flowering_date: '28/08/2023',
  //             rieping_date: '28/08/2023'
  //         },
  //         sensors: [
  //             { id: '550e8400-e29b-41d4-a716-446655440003', position: [40.12306784061817, 8.888230363952015], name: 'Soil moisture sensor', type: 'soil_moisture_sensor', eui: '2CF7F12032400040' },
  //             { id: '550e8400-e29b-41d4-a716-446655440004', position: [40.151332420654455, 8.946085860250054], name: 'Water counter', type: 'water_counter', eui: '2CF7F12032400022' },
  //             { id: '550e8400-e29b-41d4-a716-446655440005', position: [40.07922477349853, 8.878918406819578], name: 'Tree sensor (Nature 4.0)', type: 'tree_sensor', eui: '2CF7F12032400033' },
  //         ],
  //         activities: {
  //             irrigation: [
  //                 {
  //                     date: '28/08/2023',
  //                     time: '05:22',
  //                     amount: 'm^3'
  //                 }
  //             ],
  //             soil_preparation: [
  //                 {
  //                     tillage: '28/08/2023',
  //                     plough: '28/08/2023'
  //                 }
  //             ],
  //             fertilizer: [
  //                 {
  //                     type: 'type',
  //                     date: '28/08/2023',
  //                     quantity: 'kg/ha'
  //                 }
  //             ],
  //             pesticide: [
  //                 {
  //                     type: 'type',
  //                     date: '28/08/2023',
  //                     quantity: 'kg/ha'
  //                 }
  //             ]
  //         },
  //         realtime: {
  //             irrigation_profile: {
  //                 labels: ['30-June', '01-July', '02-July', '03-July', '04-July', '05-July', '06-July', '07-July'],
  //                 datasets: [
  //                     {
  //                         label: 'Acqua erogata',
  //                         data: [1450, 0, 0, 1200, 0, 0, 1250],
  //                         backgroundColor: 'rgba(53, 162, 235, 0.5)',
  //                     },
  //                     {
  //                         label: 'Acqua percolata',
  //                         data: [230, 0, 0, 0, 0, 0, 50],
  //                         backgroundColor: 'rgba(255, 99, 132, 0.5)',
  //                     },
  //                 ],
  //             },
  //             transpiration_profile: {
  //                 labels: ['0', '2', '4', '6', '8', '10'],
  //                 datasets: [
  //                     {
  //                         label: 'transpiration 1',
  //                         data: [100, 400, 800, 1600],
  //                         borderColor: 'rgb(53, 162, 235)',
  //                         backgroundColor: 'rgba(53, 162, 235, 0.5)',
  //                     },
  //                     {
  //                         label: 'transpiration 2',
  //                         data: [100, 200, 400, 800, 1600],
  //                         borderColor: 'rgb(255, 99, 132)',
  //                         backgroundColor: 'rgba(255, 99, 132, 0.5)',
  //                     },
  //                 ],
  //             },
  //             suggested_irrigation: {
  //                 quantity: '100',
  //                 optimal: '10/09/2023',
  //                 risk: '15/09/2023',
  //                 late: '20/09/2023'
  //             },
  //             sensors_data: [
  //                 {
  //                     id: '550e8400-e29b-41d4-a716-446655440003',
  //                     sensor_name: 'Soil moisture sensor',
  //                     position: [40.12306784061817, 8.888230363952015],
  //                     data: [
  //                         { label: 'Soil moisture', value: '%' },
  //                         { label: 'Soil Temperature', value: '°C' },
  //                         { label: 'Electrical conductivity', value: 'ds / m' }
  //                     ]
  //                 },
  //                 {
  //                     id: '550e8400-e29b-41d4-a716-446655440004',
  //                     sensor_name: 'Water counter',
  //                     position: [40.151332420654455, 8.946085860250054],
  //                     data: [
  //                         { label: 'Total volume', value: 'm^3' },
  //                         { label: 'Flow rate', value: 'm^3/h' },
  //                         { label: 'Water temperature', value: '°C' },
  //                         { label: 'Last applied water amount', value: 'm^3/h' },
  //                         { label: 'Last irrigation time', value: 'hh:mm:ss' },
  //                         { label: 'Irrigation status', value: 'Active / Inactive' }
  //                     ]
  //                 },
  //                 {
  //                     id: '550e8400-e29b-41d4-a716-446655440005',
  //                     sensor_name: 'Tree sensor (Nature 4.0)',
  //                     position: [40.07922477349853, 8.878918406819578],
  //                     data: [
  //                         { label: 'Value name', value: 'value' },
  //                         { label: 'Value name 2', value: 'value' },
  //                     ]
  //                 }
  //             ]
  //         }
  //     },
  //     {
  //         id: 'field-2',
  //         name: 'Field 2',
  //         polygons: [],
  //         irrigations_system: {},
  //         soil_type: {},
  //         crop: {},
  //         sensors: [],
  //         activities: {
  //             irrigation: [],
  //             soil_preparation: [],
  //             fertilizer: [],
  //             pesticide: []
  //         },
  //         realtime: {
  //             irrigation_profile: {},
  //             suggested_irrigation: {}
  //         }
  //     },
  // ];

  const waterSystem1 = {
    id: "550e8400-e29b-41d4-a716-446655440001",
    name: "Water System 1",
    systemPolygons: [
      {
        id: "550e8400-e29b-41d4-a716-446655440002",
        position: [
          [40.607046653808474, 8.461371548883896],
          [40.60662310005829, 8.462229585832548],
          [40.605254677289224, 8.468021335235898],
          [40.60597147366262, 8.46840745186277],
          [40.60590631067359, 8.469737409133204],
          [40.606525356503965, 8.47175379596251],
          [40.607405043346624, 8.473083753232903],
          [40.607046653808474, 8.473770182791817],
          [40.607698269721034, 8.474585317893034],
          [40.60844762016609, 8.473298262470076],
          [40.608643101508974, 8.472740538453426],
          [40.60984855715486, 8.473384079661749],
          [40.61033724919501, 8.474156312915532],
          [40.61186846111145, 8.47501429587693],
          [40.612226824790895, 8.475915234673016],
          [40.612943546383875, 8.477802915960018],
          [40.61121688586118, 8.480677339737978],
          [40.61134720141952, 8.483508861668502],
          [40.61190103970719, 8.486469089141329],
          [40.61160783177376, 8.48702681315798],
          [40.612226824790895, 8.48741292978485],
          [40.61304128055091, 8.488185163038635],
          [40.6127806557878, 8.490587666494852],
          [40.61160783177376, 8.494620440153465],
          [40.61242229507869, 8.495392673407247],
          [40.61440954387894, 8.488099359343765],
          [40.613204170511615, 8.48376627275311],
          [40.6156800490231, 8.47930448062015],
          [40.61515881906612, 8.475743627283277],
          [40.6142140794047, 8.476430056842192],
          [40.61401861435864, 8.475700725435843],
          [40.614246656856764, 8.474542375555188],
          [40.61431181171329, 8.473684338606537],
          [40.61457243050398, 8.471410540692622],
          [40.61496335678391, 8.469951877879925],
          [40.61388830401024, 8.467463570728837],
          [40.61473531673191, 8.466691337475096],
          [40.616299004315465, 8.466862944864795],
          [40.61835128872939, 8.46776388366088],
          [40.61900279440685, 8.467978392898052],
          [40.617602049345415, 8.466476828237923],
          [40.617243714495736, 8.46561879128927],
          [40.6178626553016, 8.46373417377722],
          [40.61851416574442, 8.462575823896566],
          [40.617113410437504, 8.462318412811959],
          [40.61613612189975, 8.463133547913177],
          [40.61502851094151, 8.462919038676004],
          [40.61502851094151, 8.462189707269655],
          [40.61379057108217, 8.462704529438831],
          [40.61379057108217, 8.461245866626134],
          [40.6144746985766, 8.460387829677522],
          [40.61535428077651, 8.461245866626134],
          [40.614767893929866, 8.459615596423738],
          [40.61506108799649, 8.458285639153306],
          [40.61600581568126, 8.45665536895091],
          [40.61506108799649, 8.456784074493214],
          [40.61398603679536, 8.457727915136696],
          [40.61362768255101, 8.458371442848176],
          [40.6128458120739, 8.457856620679],
          [40.613432215789466, 8.456312154171433],
          [40.61366026028902, 8.454767687663868],
          [40.614246656856764, 8.454510276579299],
          [40.61480047111192, 8.453051613766604],
          [40.61450727590162, 8.45227938051282],
          [40.61437696650626, 8.45124973617443],
          [40.61356252702738, 8.450734914005254],
          [40.61265034302505, 8.450906521394993],
          [40.61245487340439, 8.45004848444634],
          [40.610467566468806, 8.44949076042973],
          [40.610923674925694, 8.450262993683474],
          [40.610467566468806, 8.450305895530908],
          [40.61056530425737, 8.452150674970516],
          [40.61118430693189, 8.452708398987127],
          [40.6113797802694, 8.453223221156302],
          [40.61079335854141, 8.453437730393475],
          [40.61001145489867, 8.453437730393475],
          [40.60958791993879, 8.45348063224091],
          [40.609457600949554, 8.454338669189562],
          [40.609457600949554, 8.455153804290779],
          [40.6088385822801, 8.456011841239432],
          [40.608121816657174, 8.455840233849692],
          [40.60753536633852, 8.456355056018868],
          [40.607307300935894, 8.457813718831565],
          [40.607470204874325, 8.45897206871226],
          [40.607046653808474, 8.459572694576304],
          [40.60662310005829, 8.460173320440347],
          [40.60600405513328, 8.459486890881434],
          [40.605678239711764, 8.45991590935574],
          [40.606525356503965, 8.46111716108387],
          [40.60679338731536, 8.461117750444389],
        ],
        color: "purple",
        popup: null,
        tooltip: null,
      },
    ],
    sensors: [
      {
        id: "550e8400-e29b-41d4-a716-446655440002",
        position: [40.610369636642105, 8.463193211113097],
        name: "Water source",
        type: "water_source",
        eui: "2CF7F12032400040",
      },
      {
        id: "550e8400-e29b-41d4-a716-446655440003",
        position: [40.61144984692269, 8.450013618496907],
        name: "Water counter",
        type: "water_counter",
        eui: "2CF7F12032400040",
      },
      {
        id: "550e8400-e29b-41d4-a716-446655440004",
        position: [40.61199995312562, 8.460020498120638],
        name: "Water counter",
        type: "water_counter",
        eui: "2CF7F12032400022",
      },
      {
        id: "550e8400-e29b-41d4-a716-446655440005",
        position: [40.612465010644144, 8.48582340260463],
        name: "Water counter",
        type: "water_counter",
        eui: "2CF7F12032400033",
      },
    ],
    diagramSchema: {
      nodes: [],
      edges: [],
    },
  };

  const waterSystems = [
    waterSystem1,
    {
      id: "550e8400-e29b-41d4-a716-4466554400022",
      name: "Water System 2",
      systemPolygons: [
        {
          id: "550e8400-e29b-41d4-a716-446655440023",
          position: [
            [40.1631329, 8.8642502],
            [40.166806, 8.9641571],
            [40.0849013, 8.9590073],
            [40.077546, 8.8618469],
            [40.1631329, 8.8642502],
          ],
          color: "blue",
          popup: null,
          tooltip: null,
        },
      ],
      sensors: [],
      diagramSchema: {
        nodes: [],
        links: [],
      },
    },
  ];

  const weatherForecast = {
    data: {
      weather: "cloudy",
      temperatureMax: "18",
      temperatureMin: "23",
      humidity: "62",
      rain: "0",
      wind: "14/16",
    },
    six_mount_data: {
      labels: ["0", "2", "4", "6", "8"],
      datasets: [
        {
          label: "Curva ipsografica",
          data: [195, 165, 155, 145, 115],
          borderColor: "rgb(53, 162, 235)",
          backgroundColor: "rgba(53, 162, 235, 0.5)",
        },
        {
          label: "Altezza media",
          data: [153, 154, 155, 156, 157],
          borderColor: "rgb(255, 99, 132)",
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        },
      ],
    },
  };

  const planning = [
    {
      id: "1",
      fieldId: "field-1",
      fieldName: "Field 1",
      plannedIrrigation: "27/09/2023",
    },
    {
      id: "2",
      fieldId: "field-2",
      fieldName: "Field 2",
      plannedIrrigation: "24/10/2023",
    },
  ];

  const oveview = [
    {
      id: 10,
      bounds: [
        [40.72453629426183, 8.557487935317692],
        [40.72347886131542, 8.560862249483018],
      ],
      image: "yard",
      imageUrl: yard,
      color: "#4674c2",
    },
    {
      id: 12,
      bounds: [
        [40.725579694352525, 8.563175305928288],
        [40.72471768707962, 8.564981909772285],
      ],
      image: "forest",
      imageUrl: forest,
      color: "#ea7c3c",
    },
  ];

  const setDummyFields = () => {
    //set first system
    // dispatch(setWaterSystem(0));
    // dispatch(fetchWaterSystemsDataRequest());
    // dispatch(fetchWaterSystemsDataSuccess(waterSystems));
    // dispatch(fetchWeatherForecastDataRequest());
    // dispatch(fetchWeatherForecastDataSuccess(weatherForecast));
    // dispatch(fetchPlanningDataRequest());
    // dispatch(fetchPlanningDataSuccess(planning));
    // dispatch(fetchOveviewDataRequest());
    // dispatch(fetchOveviewDataSuccess(oveview));
  };

  //   useEffect(() => {
  //     setDummyFields();
  //   }, []);

  //   const state = useSelector((state) => state);
  //   console.log("state", state);

  return;
};

export default SetDummyData;
