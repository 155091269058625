import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import HistoricalDataComponent from "./HistoricalData.component";
import LastDataComponent from "./LastData.component";
import { createData } from "shared-components/src/components/displayData/CustomizedTables.component";
import { fetchWaterSensorsMeasureRequest } from "../../store/actions/realtimeWater.actions";

const DataFromSensorsComponent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [sensor, setSensor] = useState("");
  const waterSystems = useSelector((state) => state?.waterSystems?.data);
  const waterSystemSelected = useSelector((state) => state?.waterSystem?.data);
  const system = waterSystems.find((ws) => ws.id === waterSystemSelected);
  const sensorMeasure = useSelector(
    (state) => state?.waterSensorsMeasure?.data || []
  );
  const formattedData = Array.isArray(sensorMeasure)
    ? sensorMeasure
        .filter((item) => item !== null)
        .map((item, index) => {
          const propertyName = t(
            `real-time-page.property-name.${item?.property_name}`
          );
          const unit_of_measurement =
            item?.unit_of_measurement.match(/\(([^)]+)\)/)?.[1];
          const value = `${item?.value} ${unit_of_measurement}`;
          return {
            ...createData(propertyName, value),
            unit_of_measurement,
            key: index,
          };
        })
    : [];

  const handleChangeMeasure = (event) => {
    setSensor(event.target.value);
  };

  useEffect(() => {
    if (waterSystemSelected) {
      dispatch(fetchWaterSensorsMeasureRequest(system?.attributes?.name));
    }
    setSensor(null);
  }, [waterSystemSelected]);

  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="h6" fontWeight="bold" paddingBottom={2}>
        {t("real-time-page.data-from-sensors")}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <HistoricalDataComponent
            t={t}
            systemName={system?.attributes?.name}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <LastDataComponent
            t={t}
            sensor={sensor}
            handleChangeMeasure={handleChangeMeasure}
            sensorData={formattedData || ""}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default DataFromSensorsComponent;
